.bgHkBlue {
    background-color: #d3daf1;
}

.mobileHide {
    display: none;
}

@media (min-width: 550px) {
    .mobileHide {
        display: block;
    }
}

.pageForeground {
    position: relative;
    z-index: 1;
}

.pageBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    z-index: 0;
}

.pageBgImg {
    margin-top: 25vh;
    height: 50vh;
    width: 100%;
    background-image: url(https://herokitty.space/wp-content/uploads/background2-01.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
}

.pageBgRemainder {
    flex-grow: 1;
    flex-shrink: 1;
    background-color: #d3daf1;
}

.hkNavBar {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    font-family: "Quicksand";
    color: #2547ab;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
}

.hkHeadingPanel {
    margin-top: 3rem;
    padding: 3rem;
    text-align: center;
    background-color: #d3daf1;
    border-radius: 1rem;
    font-family: "Quicksand";
    color: #2547ab;
    border: 5px solid white;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
}

.hkHeadingPanel h3 {
    color: #2547ab;
    margin-bottom: 0;
}

.hkHeadingPanel p:last-child {
    margin-bottom: 0;
}

.hkNodesPanel {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 3rem 3rem 2rem 3rem;
    text-align: center;
    background-color: #d3daf1;
    border-radius: 1rem;
    font-family: "Quicksand";
    color: #2547ab;
    border: 5px solid white;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
}

.hkNode {
    margin-bottom: 1.5rem;
    padding: 1rem;
    text-align: center;
    background-color: white;
    border-radius: 1rem;
}

.hkNode p {
    margin-bottom: 0;
}

.hkNodeButtons {
    margin-top: 1rem;
}