body {
    height: 100%;
}

h1, h2, h3, h4, h5 {
    font-family: "Coiny";
    color: #5cb562;
}

h1 {
    font-size: 40px;
    font-weight: 500;
}

@media (min-width: 550px) {
    h1 {
        font-size: 60px;
    }
}